const loginRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/loginOrAuth/login.vue'),
    meta: {
      title: '登录',
      isNeedLogin: false,
    }
  },
  {
    path: '/regist',
    name: 'regist',
    component: () => import('@/views/loginOrAuth/regist.vue'),
    meta: {
      title: '注册',
      isNeedLogin: false,
    }
  },
  {
    path: '/viewOnWebsite',
    name: 'viewOnWebsite',
    component: () => import('@/views/beforeLoginPages/viewOnWebsite.vue'),
    meta: {
      title: '提示',
      isNeedLogin: false,
    }
  },
]
export default loginRoutes;