import axios from "axios";
import store from "../store/index";
import router from "../router";
import { showNotify } from "vant";
import { createTeamPrompt, showDialog } from "@/util/createTeamPrompt";
import utils from "@/util/util";

import NProgress from "nprogress";
import "nprogress/nprogress.css";
NProgress.configure({ showSpinner: false, parent: "html" });

let aiHost = process.env.VUE_APP_AI_API_URL;

const service = axios.create({
  timeout: 1000 * 60 * 5,
});
// request拦截器
service.interceptors.request.use(
  config => {
    // const vuexDATA = JSON.parse(localStorage.getItem("vuex"));
    // const token = vuexDATA?.token;
    const token = localStorage.getItem("token");
    NProgress.start(); // 开始加载进度条
    config.headers["token"] = token || "";
    return config;
  },
  error => {
    showNotify({
      message: "登录失败！",
      type: "danger",
    });
    NProgress.done(); // 结束进度条
    return Promise.reject(error);
  }
);
// 响应拦截器
service.interceptors.response.use(
  response => {
    NProgress.done(); // 结束进度条
    var { msg, code } = response.data;
    // console.log("=======================");
    // console.log(response.request.responseURL);
    // console.log(response.data);
    // console.log("=======================");

    if (code === 20009) {
      showNotify({
        message: msg,
        type: "danger",
      });
    } else if (code === 20008) {
      showDialog({
        theme: "round-button",
        message: msg,
      }).then(() => {
        window.location.reload();
      });
    } else if (code === 20007) {
      showDialog({
        theme: "round-button",
        message: "请先创建团队",
      }).then(() => {
        noTeamHandler();
      });
    } else if (code === 20001) {
      localStorage.clear();
      // store.commit("CLEAR_USER_INFO");
      showNotify({
        message: "登录过期，请重新登录！",
        type: "danger",
        duration: 500,
        onClose: () => {
          if (utils.isWX()) {
            window.location.reload();
          } else {
            router.push("/login")
          }
        },
      });
    } else if (code === 1) {
      return Promise.resolve(response);
    } else if (code === 30001) {
      showNotify({
        message: "请将此微信号与您已有的JoinMC账号绑定",
        type: "warning",
      });
      return Promise.resolve(response);
    } else {
      showNotify({
        message: msg,
        type: "danger",
      });
      return Promise.reject(response);
    }
  },
  error => {
    NProgress.done(); // 结束进度条
    if (error.code === "ECONNABORTED" || error.message?.includes("timeout")) {
      showNotify({
        message: error.message,
        type: "danger",
      });
      return Promise.reject({
        code: 403,
        msg: "请求超时，请稍后尝试",
      });
    }
    if (error.response.status === 500) {
      showNotify({
        message: error.response.statusText,
        type: "danger",
      });
      return Promise.reject({
        code: error.response.status,
        msg: error.response.statusText,
      });
    }
  }
);

var axios_ = function (method, url, params = {}) {
  if (method.toLowerCase() == "get") {
    return new Promise((resolve, reject) => {
      service
        .get(aiHost + url, {
          params: params,
        })
        .then(response => {
          if (response?.data) {
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  } else {
    return new Promise((resolve, reject) => {
      service({
        method: method,
        url: aiHost + url,
        data: params,
      })
        .then(response => {
          if (response?.data) {
            resolve(response.data);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

var noTeamHandler = async function () {
  axios_("GET", "api/pandagpt/v1/getGptModel").then(res => {
    if (res.code === 1) {
      createTeamPrompt({
        models: res.data.data,
        onConfirm: data => {
          axios_("post", "api/pandagpt/v1/team/insert", { team_name: data.team_name, gpt_model_id: data.gpt_model_id }).then(res => {
            if (res.code === 1) {
              store.commit("CHANGE_TEAM_PARAM", {
                curr_team_id: res.data.team_id,
                curr_team_name: data.team_name,
                gpt_model_id: data.gpt_model_id,
                team_storage_id: res.data.team_storage_id,
              });

              store.commit("SET_GLOBAL_MODEL_ID", data.gpt_model_id);
              store.dispatch("getUserTeamList");

              router.push("/");
            } else {
              showNotify({
                message: res.msg,
                type: "warning",
              });
            }
          });
        },
        onCancel: () => {
          console.log("Dialog canceled");
        },
      });
    } else {
      showNotify({
        message: res.msg,
        type: "warning",
      });
    }
  });
};
export default axios_;
