import { computed, reactive } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    // 导入 useStore 钩子函数
    const store = useStore();
    // 使用 computed 来获取 theme 状态
    const theme = computed(() => store.state.theme);
    const themeVarsDark = reactive({
      // 这里是 dark 主题的变量
    });
    const themeVarsLight = reactive({
      // 这里是 light 主题的变量
      // primaryBackground: "#FF9800",
      // primaryColor: "#FF9800",
      // successColor: "#00C853",
      // successBackground: "#00C853",
      // buttonDefaultBorderColor: "#ebedf0",
    });
    return {
      theme,
      themeVarsDark,
      themeVarsLight
    };
  }
};