import { createStore } from "vuex";
import loginFetch from "@/axios/apis/loginFetch";
import createPersistedState from "vuex-persistedstate";

const store = createStore({
  state: {
    theme: "light",
    user_info: {},
    curr_team: {},
    token: "",
    invite_id: "",
    gpt_model_id: "",
    gptConfigDatas: {},
    gpt_models: [],
    charge_rules: [],
    team_list: [],
    leftPopupVisible: false,
  },
  mutations: {
    SET_LEFT_POPUP_VISIBLE(state, visible) {
      state.leftPopupVisible = visible;
    },
    // 定义更改主题颜色的方法
    SET_THEME(state, theme) {
      state.theme = theme;
    },
    // 保存登录信息，token等
    SET_USER_INFO(state, loginResponse) {
      state.user_info = loginResponse.user_info;
      state.token = loginResponse.token;
      localStorage.setItem("token", loginResponse.token);
    },
    // 清除登录信息，token等
    CLEAR_USER_INFO(state) {
      state.user_info = {};
      state.token = "";
      state.curr_team = {};
      state.gpt_model_id = "";
      state.gpt_models = [];
      state.team_list = [];
      localStorage.clear();
    },
    // 缓存邀请链接里的id
    SET_INVITE_ID(state, id) {
      state.invite_id = id;
    },
    // 设置团队默认的AI模型版本
    SET_GLOBAL_MODEL_ID(state, id) {
      state.gpt_model_id = id;
    },
    // 缓存global数据
    SET_CONFIG_DATA(state, data) {
      state.gptConfigDatas = data;
    },
    // 缓存GPT模型列表
    SET_GPT_MODEL_LIST(state, data) {
      state.gpt_models = data;
    },
    // 模型收费标准
    SET_GPT_CHARGE_DATA(state, data) {
      state.charge_rules = data;
    },
    SET_TEAM_LIST(state, data) {
      state.team_list = data;
      state.curr_team = data.find(item => item.is_use === 1);
      location.href = "/"
    },
    // 切换团队信息
    CHANGE_TEAM_PARAM(state, data) {
      state.user_info.curr_team_id = data.curr_team_id;
      state.user_info.curr_team_name = data.curr_team_name;
      state.user_info.gpt_model_id = data.gpt_model_id;
      state.user_info.team_storage_id = data.team_storage_id;

      state.system_storages = [];
      state.global_gpt_model_id = data.gpt_model_id;
      state.team_storage_id = data.team_storage_id;
    },
  },
  actions: {
    async getGptConfigDatas(context) {
      const res = await loginFetch.getGptConfigDatas();
      context.commit("SET_CONFIG_DATA", res.data);
    },
    async getAllGptModels(context) {
      const res = await loginFetch.getGptModels();
      context.commit("SET_GPT_MODEL_LIST", res.data.data);
    },
    async getBillingRules(context) {
      const res = await loginFetch.billingRules();
      context.commit("SET_GPT_CHARGE_DATA", res.data);
    },
    async getUserTeamList(context) {
      const res = await loginFetch.getUserTeamList();
      if (res.data.data?.length > 0) {
        context.commit("SET_TEAM_LIST", res.data.data);
      }
    },
  },
  getters: {
    leftPopupVisible(state) {
      return state.leftPopupVisible;
    },
    getTeamList(state) {
      if (state.token && state.team_list?.length === 0) {
        store.dispatch("getUserTeamList");
      }
      return state.team_list;
    },
    getGptModels(state) {
      if (state.token && state.gpt_models?.length === 0) {
        store.dispatch("getAllGptModels");
      }
      return state.gpt_models;
    },
    getGptModelsChargeRules(state) {
      if (state.token && state.charge_rules?.length === 0) {
        store.dispatch("getBillingRules");
      } 
      return state.charge_rules;
    },
    getCurrTeam(state) {
      return state.curr_team;
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],
})
export default store;
