const loginedRoutes = [
  {
    path: "/",
    name: "AiHome",
    redirect: "/chat",
    component: () => import("@/views/tools/Index.vue"),
    meta: {
      title: "欢迎体验JoinMC",
      isNeedLogin: true,
    },
    children: [
      {
        path: "/chat",
        name: "chatPage",
        component: () => import("@/views/tools/chat.vue"),
        meta: {
          title: "对话",
          isNeedLogin: true,
        },
      },
    ],
  },
];
export default loginedRoutes;
